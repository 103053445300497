<template>
  <div class="bg">
    <div class="top">
      <img src="../../../assets/img/inquiry_bg.png" alt="">
         <h1>中国人民解放军总医院301医院</h1>
      <div class="tagbox">
        <span class="tag"> 三甲医院 </span>
      </div>
      <div class="sumbox">
        <p><span>35科室</span><span>350位医生</span></p>
      </div>
      <div class="position">
        <span><van-icon name="location-o" /> 湖北省武汉市洪山区北京路58号</span>
      </div>
    </div>
    <div class="html" v-html="html">
      
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
          html:'<h1>中国人民解放军总医院301医院</h1>'
    };
  },
  methods: {
    goinfo(){
       this.$router.push({ name: "hospitalinfo" });

    }
  },
};
</script>
<style lang="less" scoped>
.bg {
  .html{
    background: #fff;
    padding: 16px;
    text-align: left;
  }
  .top {
    width: 375px;
    height: 438px;
    background: #1daefc;
    padding: 16px;
    text-align: left;
    img{
      width: 343px;
      height: 257px;
      border-radius: 8px;
      margin-bottom: 16px;
    }
    h1 {
      color: #ffffff;
      font-size: 18px;
      line-height: 49px;
      font-weight: 400;
    }
    .tagbox {
      margin-bottom: 10px;
      .tag {
        display: inline-block;
        padding: 4px 10px;
        color: rgba(29, 174, 252, 1);
        background: #e2f5ff;
        border-radius: 4px;
      }
    }
    .sumbox {
      p {
        color: #e2f5ff;
        margin-bottom: 10px;
        font-size: 16px;
        text-align: left;
        &.f14 {
          font-size: 14px;
        }
        span {
          margin-right: 32px;
          font-size: 14px;
        }
      }
    }
    .position {
      color: #e2f5ff;
      margin-bottom: 25px;
    }
    .info {
      width: 343px;
      height: 88px;
      background: #4abffe;
      border-radius: 8px;
      padding: 16px;
      color: #fff;
      position: relative;
      img {
        position: absolute;
        width: 6px;
        height: 12px;
        right: 19px;
        top: 50%;
        transform: translateY(-50%);
      }
      .sl {
        width: 286px;
      }
    }
  }
  .h1 {
    padding: 16px;
    font-size: 16px;
    color: rgba(45, 45, 45, 1);
    text-align: left;
    background: #fff;
  }
}
</style>
