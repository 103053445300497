import { render, staticRenderFns } from "./hospitalinfo.vue?vue&type=template&id=44eacf53&scoped=true&"
import script from "./hospitalinfo.vue?vue&type=script&lang=js&"
export * from "./hospitalinfo.vue?vue&type=script&lang=js&"
import style0 from "./hospitalinfo.vue?vue&type=style&index=0&id=44eacf53&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44eacf53",
  null
  
)

export default component.exports